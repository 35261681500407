export const LARGE_PRODUCT_ENTITY_INFO_FRAGMENT = `
  fragment largeProductEntityInfoFragment on LargeProduct {
    uid
    schemaCode
    name
    dimensions
    manufacturer
    model
    serie
    serialNumber
    price
  }
`;
